<template>

  <div class="card">
    <div class="card-header header-elements-inline">
      <h5 class="card-title">Note Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <!--<a class="list-icons-item" data-action="collapse"></a>-->
          <!--<a class="list-icons-item" data-action="reload"></a>-->
          <!--<a class="list-icons-item" data-action="remove"></a>-->
        </div>
      </div>
    </div>

    <div class="card-body">

        <div class="form-group row">
          <label class="col-md-1 col-form-label font-weight-semibold">Title:</label>
          <div class="col-md-11">
            <input  id="txttitle" type="text" class="form-control" placeholder="Note Title"  autofocus="" required="" v-if="itnote" v-model="itnote.title">
          </div>
        </div>

      <div class="form-group row">
        <label class="col-md-1 col-form-label font-weight-semibold">Type:</label>
        <div class="col-md-2">
          <select class="form-control" v-if="itnote" v-model="itnote.type">
            <option value="1">Permission</option>
            <option value="3">Ratification</option>
            <option value="2">Information</option>
          </select>
        </div>

        <div class="col-md-6">
        </div>
        <div class="col-md-1 text-right">
            <label class="col-form-label font-weight-semibold">Date : </label>
        </div>

        <div class="col-md-2">
          <input type="date" class="form-control" v-if="itnote" v-model="itnote.date">
        </div>
      </div>


      <div class="form-group row">
          <label class="col-md-1 col-form-label font-weight-semibold">Justification:</label>
          <div class="col-md-11">
            <textarea id="content-editor" class="form-control" placeholder="Enter your message here" v-if="itnote" v-model="itnote.jusdification"></textarea>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-primary" @click="saveDocument">Submit<i class="icon-paperplane ml-2"></i></button>
        </div>

    </div>
  </div>

</template>

<script>
  import moment from 'moment-timezone';
  import {userService} from '@/store/auth-header.js';
  export default {
    name: 'NoteForm',
    components: {

    },
    props: {
      mynote: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"branch":{"id":"","code":0,"name":""},"date":"0001-01-01","title":"","type":0,"jusdification":"","list":[]}')
      },
      FinancialYear: {
        type: Number,
        required: false,
        default: () => 2000
      }
    },
    data () {
      return {
        itnote: JSON.parse('{"id":"","code":0,"status":0,"branch":{"id":"","code":0,"name":""},"date":"0001-01-01","title":"","type":0,"jusdification":"","list":[]}')
      }
    },
    beforeMount () {
      this.itnote = this.mynote; // save props data to itself's data and deal with tms
    },
    created () {
      let self = this;
      self.$data.itnote.date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      let self = this;

      if(tinymce.get('content-editor') != null){
        tinyMCE.remove();
      }
      self.initEditor();


      $('.form-control-select2').select2()
      $('.form-input-styled').uniform({
        fileButtonClass: 'action btn bg-pink-400'
      });

      $('#txttitle').focus();
    },
    methods: {
      initEditor () {
        let self = this;
        try {
          tinymce.init({
            selector: "#content-editor",
            images_upload_credentials: true,
            image_title: true,
            automatic_uploads: true,
            width: '100%',
            height: 230,
            plugins: ["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak",
              "searchreplace wordcount code visualblocks visualchars fullscreen insertdatetime media nonbreaking",
              "table contextmenu directionality emoticons template textcolor paste fullpage textcolor colorpicker textpattern noneditable"
            ],
            toolbar1: "print |bullist numlist outdent indent | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect",
            statusbar: true,
            menubar: true,
            file_picker_types: 'image',
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = function () {
                var file = this.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);

                  // call the callback and populate the Title field with the file name
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };

              input.click();
            },
            setup: function (editor) {
              editor.on('init', function (e) {
                try {
                  $(".mce-branding").hide();
                  tinymce.get('content-editor').setContent(self.$data.itnote.jusdification);
                }catch (e) {

                }
              });
            }
          });
        } catch (ex) {
          alert(ex);
        }
      },
      iconFormat (icon) {
        var originalOption = icon.element
        if (!icon.id) {
          return icon.text
        }
        var $icon = '<i class=\'icon-' + $(icon.element).data('icon') + '\'></i>' + icon.text

        return $icon
      },
      clear(){

      },
      saveDocument() {
        let self = this;

        if(self.$data.itnote.title.trim().length < 5){
          alert("Invalid Title");
          return
        }

        self.$data.itnote.branch.code =  1;
        self.$data.itnote.type = parseInt(self.$data.itnote.type);
        self.$data.itnote.date = moment(self.$data.itnote.date).format('YYYY-MM-DD') + 'T00:00:00Z';
        self.$data.itnote.jusdification = tinymce.get('content-editor').getContent();


        const requestOptions = {
          method:  (self.$data.itnote.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.itnote)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/it/note/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.itnote = JSON.parse('{"id":"","code":0,"status":0,"branch":{"id":"","code":0,"name":""},"date":"0001-01-01","title":"","type":0,"jusdification":"","list":[]}');
            self.$parent.$parent.closeModal();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
